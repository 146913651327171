<template>
  <!--列表页-->
  <div :style="{backgroundImage:'url('+bgimg+')','background-size':'100% auto'}">
    <div style="color: #999;font-size: 14px;line-height: 50px;width: 1200px;margin:0 auto;">
      当前位置  <span style="margin: 0 10px">/</span>  科技服务  <span style="margin: 0 10px">/</span> <span style="color: #1890FF">服务列表</span>
    </div>
    <div class="center" style="padding: 8px 24px 17px;background: #fff;border-radius: 8px;">
      <div class="service_div">
        <div style="width: 1180px;margin: 0 auto;" class="dp-flex">
          <div style="margin-right: 80px;white-space: nowrap;">相关分类</div>
          <span
            v-for="(item,index) in fwfl"
            :key="item.name"
            @click="fwflChange(item,index)"
            :class="{ select_span: fwflChoice == index}"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <div style="background-color: white" v-show="firstChoose" class="service_div">
        <div style="width: 1180px; margin: 0 auto;" class="dp-flex">
          <div style="margin-right: 80px;white-space: nowrap;">二级分类</div>
          <div>
            <span
              v-for="(item,index) in fwbq"
              :key="item.name"
              @click="fwbqChange(item,index)"
              :class="{ select_span: fwbqChoice == index }"
              >{{ item.name }}</span
            >
          </div>
        </div>
      </div >
      <div style="background-color: white" v-show="secondChoose" class="service_div">
        <div style="width: 1180px; margin: 0 auto" class="dp-flex">
          <div style="margin-right: 80px;white-space: nowrap;">三级分类</div>
          <div>
            <span
                v-for="(item,index) in sjfl"
                :key="item.name"
                @click="sjflChange(item,index)"
                :class="{ select_span: sanjiChoice == index }"
            >{{ item.name }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="center" id="service_list" style="background: #fff;border-radius: 8px;">
      <div class="center" id="order" style="margin-top: 10px;background-color: #F5F5F5;height: 40px;line-height: 40px;font-size: 14px;border-radius: 8px 8px 0 0;">
      <span
              :class="['sort-label', sortField === 'NONE' ? 'active' : '']"
              style="padding-left: 10px"
              @click="sortList('NONE')"
      >默认</span
      >
        <span @click="sortList('PRICE')" :class="['sort-label-con',sortField === 'PRICE' ? 'active' : '',sortField === 'PRICE' && !sortOrder?'activeASC':'']">
          价格
        <Icon type="md-arrow-dropup" />
                        <Icon type="md-arrow-dropdown" />
      </span>
        <span @click="sortList('SALES')" :class="['sort-label-con',sortField === 'SALES' ? 'active' : '',sortField === 'SALES' && !sortOrder?'activeASC':'']">
          销量
        <Icon type="md-arrow-dropup" />
          <Icon type="md-arrow-dropdown" />
      </span>
      </div>
<!--      商品列表-->
      <div class="gd-list" >
        <div
          v-for="item of serviceList"
          :key="item.id"
          class="list-item"
          @click="goDetail(item.id)"
        >
          <div style="height: 250px">
            <img :src="item.mainImageUrl" alt="" style="width: 100%;height: 100%;object-fit: cover" />
<!--            <img style="width: 250px;height: 250px;" :src="defaultImg" />-->
<!--            <img v-if="item.labelValue == '发明专利'" style="width: 250px;height: 250px;" src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/famingzhuanli.jpg" />-->
<!--            <img v-else-if="item.labelValue == '实用新型'" style="width: 250px;height: 250px;" src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/shiyongxinxing.jpg" />-->
<!--            <img v-else-if="item.labelValue == '外观设计'" style="width: 250px;height: 250px;" src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/waiguanzhuanli.jpg" />-->
<!--            <img v-else style="width: 250px;height: 250px;" src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/shangbiaozhuce.jpg" />-->
          </div>
            <div style="height: 50px; line-height: 50px; color: #FF6A00">
              <span style="font-size: 16px">¥ </span>
              <span style="font-size: 24px">{{ item.price }}</span>
            </div>
            <div class="good-name yi_hang_sheng">
              {{ item.name }}
            </div>
            <div class="good-label">
              <div style="float: left;padding-top: 3px">
                <span class="yi_hang_sheng" style="font-size: 14px;line-height: 15px;width: 100px;display: inline-block">{{ item.shopName }}</span>
              </div>
              <div style="float: right">
                <span style="font-size:14px;">{{item.labelValue}}</span>
              </div>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-around;
              "
            >
              <div class="good-btn" style="width: 100%">
                <div class="consult" @click.stop="openZoosUrl">
                  立即咨询
                </div>
                <div class="buybtn" @click.stop="goPay(item.id)">立即购买
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div  STYLE="overflow: hidden;width:100%;width: 1200px;margin: 0 auto">
        <Page
          :total="listTotalNum"
          :page-size="listQuery.pageSize"
          show-elevator
          style="margin-top: 30px; float: right; margin-bottom: 30px"
          @on-change="changePage"
        />
      </div>
  </div>
</template>

<script>
import {
  getScienceServiceList,
  getScienceServiceLabelList,
  getClassifyCodeList,
} from "@/plugins/api/scienceServiceApi";
import {getLocalToken} from "@/plugins/tools/storage";
import {sendComment,goodsRest} from "@/plugins/api/Good";
import {successToast} from "@/plugins/tools/util";
import {errorToast} from "../../../plugins/tools/util";
import bgimg from "@/assets/image/bgimg.png";
import defaultImg from "@/assets/image/defaultImg.png";

export default {
  name: "ScienceServiceList",
  data() {
    return {
      firstChoose:false,
      secondChoose:false,
      fwfl: [],
      fwbq: [],
      sjfl:[],
      fwbqChoice: 0,
      sanjiChoice:0,
      fwflChoice: "",
      serviceList: [],
      listTotalNum: 0,
      listQuery: {
        code:'',
        pageSize: 8,
        pageNum: 1,
      },
      isallclassify:false,
      sortField: "NONE",
      sortOrder: false,
      consultText: '',
      shopId:"",
      bgimg: bgimg,
      defaultImg: defaultImg,
    };
  },
  // watch: {
  //   $route: {
  //     immediate: true, // 一旦监听到路由的变化立即执行
  //     handler(to, from) {
  //
  //       if (JSON.stringify(to.params) !== "{}") {
  //         this.fwflChoice = to.query.code || "";
  //         this.initNav(to.query.code);
  //         this.getScienceServiceList(to.query.code);
  //       }
  //     },
  //   },
  // },
  async mounted() {
    this.initNav();
  },

  computed: {
    sortPriceAsc() {
      return this.sortField === "PRICE" && this.sortOrder;
    },
    sortPriceDesc() {
      return this.sortField === "PRICE" && !this.sortOrder;
    },
    sortSalesAsc() {
      return this.sortField === "SALES" && this.sortOrder;
    },
    sortSalesDesc() {
      return this.sortField === "SALES" && !this.sortOrder;
    },
  },
  methods: {
    changeclassifyshow() {
      this.isallclassify = !this.isallclassify
    },
    openZoosUrl() {
      openZoosUrl('chatwin');
    },
    async initNav() {
      let fl = await getClassifyCodeList({});
      if (fl.code === 0) {
        this.fwfl = [{ name: "全部分类", code: "" }].concat(fl.result || []);
        this.fwbq = [{ name: "全部分类", code: "" }]
        this.sjfl = [{ name: "全部分类", code: "" }]
        this.getScienceServiceList()
      }
    },
    async getScienceServiceList(sortField, sortOrder) {
      let param = {};
      param.code = this.listQuery.code;
      if (sortField) {
        param.sortField = sortField;
      }
      if (sortOrder) {
        param.sortOrder = sortOrder;
      }
      param = Object.assign({}, param, this.listQuery);

      let data = await getScienceServiceList(param);
      if (data.code === 0) {
        this.serviceList = data.result.list || [];
        this.listTotalNum = data.result.total;
      }
    },
    changePage(index) {
      this.listQuery.pageNum = index;
      this.getScienceServiceList(this.fwflChoice, this.fwbqChoice);
    },
    sortList(field) {
      this.sortField = field;
      this.sortOrder = !this.sortOrder;
      this.listQuery.pageNum = 1;
      this.getScienceServiceList(
        this.sortField,
        this.sortOrder ? "ASC" : "DESC"
      );
    },
    resetQuery() {
      this.listQuery.pageNum = 1;
      this.sortField = "NONE";
    },
    fwflChange(item,index) {
      this.fwflChoice = index
      this.resetQuery();
      this.listQuery.code = item.code;
      this.getScienceServiceList()
      this.fwbq = [{ name: "全部分类", code: item.code }].concat(item.children || []);
      this.sjfl= [{ name: "全部分类", code:item.code}]
      this.fwbqChoice = 0;
      this.sanjiChoice = 0;
      if(item.name != '全部分类'){
        this.firstChoose = true;
        this.secondChoose = false;
      }else {
        this.firstChoose = false;
        this.secondChoose = false;
      }
    },
    sjflChange(item,index){
      this.sanjiChoice = index
      this.listQuery.code = item.code;
      this.resetQuery();
      this.getScienceServiceList()
    },
    fwbqChange(item,index) {
      this.fwbqChoice = index
      this.resetQuery();
      this.sjfl= [{ name: "全部分类", code:item.code }].concat(item.children || []);
      this.listQuery.code = item.code;
      this.getScienceServiceList()
      this.sanjiChoice = 0;
      if(item.name != '全部分类'){
        this.secondChoose = true
      }else{
        this.secondChoose = false;
      }
      // let flCode = this.fwflChoice ? this.fwflChoice.code || "" : "";
      // this.$router.push({
      //   path: `serviceList?code=${flCode}&label=${item.id}`,
      // });
    },
    sendComment() {
      sendComment({
        shopId: this.shopId,
        reviewContent: this.consultText
      }).then(res => {
        if (res.code === 0)
          successToast("咨询成功")
      })
    },
    checkLogin() {
      if (!getLocalToken()) {
        this.$Modal.confirm({
          draggable: true,
          onOk: async () => {
            this.$router.push({ name: "login" });
          },
          render: (h) => {
            return h("div", [
              h(
                "div",
                {
                  style:
                    "text-align:center;margin-bottom:20px;padding-top:20px;vertical-align: middle;font-size: 16px;color: #17233d;",
                },
                "请先登录后再试"
              ),
            ]);
          },
        });
        return false;
      } else {
        return true;
      }
    },
    consulting(item) {
      this.shopId = item.shopId;
      const state = this.checkLogin()
      if(!state){
        return
      }

      this.$Modal.confirm({
        draggable: true,
        onOk: async () => {
          this.sendComment();
        },
        render: (h) => {
          return h("div", [
            h(
              "div",
              {
                style:
                  "margin-bottom:20px;vertical-align: middle;font-size: 16px;color: #17233d;font-weight: 700;",
              },
              "咨询内容"
            ),
            h("Input", {
              style: "width:100%;",
              props: {
                value: this.consultText,
                autofocus: true,
                placeholder: "请输入咨询内容",
                type: "textarea",
                autosize: true
              },
              on: {
                input: (val) => {
                  this.consultText = val;
                }
              }
            }),
          ]);
        }
      });
    },


    async goPay(id) {
      let json = await goodsRest({goodsId:id,num:1})
      if(json.code ==0){
        if(json.result == true){
          this.$router.push({
            path: `servicePay?goodId=${id}`,
          });
        }else{
          errorToast(json.message)
        }
      }else{
        errorToast(json.message)
      }

    },
    goDetail(id) {
      this.$router.push({
        path: `serviceDetail?goodId=${id}`,
      });
    }
  }
};
</script>


<style scoped lang="scss">
/deep/ .ivu-page-item-active {
  background-color: #2d8cf0;
  color: white !important;
  border: none;
}

/deep/ .ivu-page-item-active a {
  color: white !important;
}
.pay_div {
  display: flex;
  height: 60px;
  width: 180px;
  border: 1px solid rgb(205, 240, 243);
  margin-left: 20px;
  font-size: 20px;
  align-items: center;
  cursor: pointer;
}

.consult {
  float: left;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  width: 120px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #1890FF;
  color: #1890FF;
}

.buybtn {
  float: right;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  width: 120px;
  height: 36px;
  background: #1890FF;
  color: #fff;
  border-radius: 4px;
}

.div_button {
  cursor: pointer;
  border-width: 1px;
  border-color: rgb(220, 220, 220);
  border-style: solid;
  border-radius: 4px;
  display: inline-block;
  width: 114px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.div_button_select {
  cursor: pointer;
  border-width: 1px;
  border-color: #FF6A00;
  border-style: solid;
  border-radius: 4px;
  display: inline-block;
  width: 114px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #FF6A00;
}

.button_style {
  font-size: 12px;
  margin-left: 10px;
  display: inline-block;
  border-radius: 2px;
  background-color: rgb(54, 194, 207);
  width: 75px;
  height: 40px;
  z-index: 51;
  line-height: 40px;
  text-align: center;
  color: white;
  cursor: pointer;
}

#button_style div:nth-child(1) {
  margin-left: 50px;
}

#button_style div:nth-child(2) {
  margin-left: 25px;
}

.service_list > div:hover {
  box-shadow: 0 6px 20px -6px rgba(0, 0, 0, 0.1);
}

#order > span {
  display: inline-block;
  cursor: pointer;
  width: 90px;
  text-align: center;
  color: #999;
  position: relative;
}

.service_div {
  font-size: 14px;
  line-height: 32px;
  margin-top: 9px;

  & span {
    display: inline-block;
    height: 32px;
    margin-right: 20px;
    cursor: pointer;
    color: #333;
    padding: 0 10px;
  }
}

.select_span {
  text-align: center;
  background: #1890FF;
  border-radius: 4px;
  color: #fff !important;
}

.dpyx {
  & span {
    margin: 0 5px;
  }
}

.left_desc {
  padding-left: 20px;
  padding-top: 5px;
}

.rmzl_content_real {
  padding: 20px 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  align-items: center;
}

.liang_hang_sheng {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.rmzl_title_price .desc {
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.rmzl_image {
  width: 150px;
  height: 180px;
  float: left;
}

.rmzl_title_price {
  width: 228px;
  height: 180px;
  line-height: 1;
  float: left;
  box-sizing: border-box;
  padding: 20px 15px;
}

.div_center {
  width: 100%;
}

.center {
  width: 1200px;
  margin: 0 auto;
}
#order i:first-child{
  position: absolute;
  top: 10px;
}

#order i:last-child{
  position: absolute;
  top: 17px;
}

#order>.active {
  color: #1890FF!important;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
}

.active i:first-child {
  color: #1890FF;

}

.active i:last-child {
  color: #999;
}

.activeASC i:first-child {
  color: #999;
}

.activeASC i:last-child {
  color: #1890FF;
}

.sort-label-con {
  > span {
    display: inline-block;
    width: 5px;
    margin-right: 10px;
  }
}

.list-item {
  width: 270px;
  height: 430px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #E5E8ED;
  padding: 10px;
  &:hover {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  }
}

.gd-list {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  justify-items: center;
  align-items: center;
  padding: 20px 0 40px;
  grid-row-gap: 30px;
}
.good-name {
  font-size: 16px;
  color: #333333;
  height: 22px;
  font-weight: bold;
  width: 250px;
  cursor: pointer;
}
.yi_hang_sheng {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.good-label {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  overflow: hidden;
  color: #999;
  font-weight: 400;
}
</style>
